import React, { useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { FaCheckDouble, FaTimes, FaTrash, FaUndo } from 'react-icons/fa';
import axios from 'axios';
import DeleteNotificationModal from './deleteModal';
import toast from 'react-hot-toast';

const slideIn = keyframes`
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0); 
    opacity: 1;
  }
`;

const slideOut = keyframes`
  0% {
    transform: translateX(0); 
    opacity: 1;
  }
  100% {
    transform: translateX(100%); 
    opacity: 0;
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4); /* Semi-transparent background */
  backdrop-filter: blur(8px); /* Blur effect */
  z-index: 99; /* Place it behind the modal */
  display: ${props => (props.isModalOpen ? 'block' : 'none')}; /* Show only when modal is open */

  @media (min-width: 769px) {
    display: none; /* Disable overlay for non-mobile screens */
  }
`;

const NotificationsModalContainer = styled.div`
  position: absolute;
  top: 100px;
  right: 5px;
  background-color: white;
  width: 450px;
  max-height: 400px;
  overflow-y: auto;
  padding: 15px 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  border: 2px solid #8A3518;
  z-index: 100;
  animation: ${props => (props.isClosing ? slideOut : slideIn)} 0.4s ease-out;

  /* Responsive adjustments for mobile */
  @media (max-width: 768px) {
    width: 90%; /* Reduce the width more on the left */
    top: 80px; /* Positioning adjustment */
    right: 2%; /* Move the modal to the left */
    padding: 10px; /* Reduce padding */
    max-height: 350px; /* Adjust max height for mobile */
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #8A3518;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #c0392b;
  }

  scrollbar-width: thin;
  scrollbar-color: #8A3518 #f1f1f1;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    margin-bottom: 8px;
  }
`;

const Title = styled.h4`
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 16px; /* Reduce title font size for mobile */
  }
`;

const MarkAllRead = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  color: #8A3518;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${props => (props.disabled ? 0.5 : 1)};

  svg {
    margin-right: 5px;
  }

  @media (max-width: 768px) {
    font-size: 11px; /* Smaller font size for mobile */
  }
`;

const TabsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 10px;
  background-color: #f1f1f1;
  padding: 5px 8px;
  border-radius: 10px;
  margin-bottom: 15px;
  white-space: nowrap;

  @media (max-width: 768px) {
    grid-gap: 5px; /* Reduce gap between tabs */
    padding: 4px; /* Reduce padding on mobile */
  }
`;

const Tab = styled.div`
  font-size: 11px;
  font-weight: bold;
  color: ${props => (props.active ? '#8A3518' : '#888')};
  background-color: ${props => (props.active ? '#fff' : 'transparent')};
  padding: 4px 8px;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${props => (props.active ? '#ddd' : 'transparent')};
  box-shadow: ${props => (props.active ? '0px 2px 5px rgba(0,0,0,0.1)' : 'none')};
  height: 25px;
  min-width: 60px;
  transition: all 0.3s ease;

  @media (max-width: 768px) {
    font-size: 10px; /* Smaller font size for mobile */
    padding: 3px 6px; /* Adjust padding */
  }
`;

const TabCount = styled.div`
  margin-left: 4px;
  font-size: 11px;
  font-weight: bold;
  background-color: ${props => (props.active ? '#F1C6A6' : '#D3D3D3')};
  border-radius: 4px;
  padding: 2px 4px;
  min-width: 15px;
  text-align: center;
  transition: background-color 0.3s ease;

  @media (max-width: 768px) {
    font-size: 9px; /* Smaller font size for mobile */
    padding: 1px 3px; /* Adjust padding */
  }
`;

const NotificationItem = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;

  @media (max-width: 768px) {
    margin-bottom: 8px; /* Reduce spacing on mobile */
    padding-bottom: 8px;
  }
`;

const ProfileImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;

  @media (max-width: 768px) {
    width: 35px; /* Smaller profile image */
    height: 35px;
    margin-right: 8px; /* Reduce spacing */
  }
`;

const NotificationContent = styled.div`
  flex: 1;
`;

const NotificationHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const NotificationText = styled.p`
  margin: 0;
  font-size: 14px;
  color: #333;

  @media (max-width: 768px) {
    font-size: 13px; /* Smaller font size for mobile */
  }
`;

const Timestamp = styled.p`
  font-size: 12px;
  color: #888;
  margin: 5px 0 0;

  @media (max-width: 768px) {
    font-size: 11px; /* Smaller font size for mobile */
    margin-top: 3px; /* Reduce spacing */
  }
`;

const NoNotifications = styled.p`
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
  color: #888;

  @media (max-width: 768px) {
    font-size: 13px; /* Adjust font size for mobile */
  }
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;

  @media (max-width: 768px) {
    margin-top: 8px; /* Reduce margin on mobile */
  }
`;

const PageButton = styled.button`
  padding: 7px 10px;
  margin: 0 5px;
  background-color: ${props => (props.disabled ? '#D3D3D3' : '#8A3518')};
  color: white;
  border: none;
  border-radius: 5px;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};

  @media (max-width: 768px) {
    padding: 6px 8px; /* Smaller padding on mobile */
    font-size: 12px; /* Adjust font size for mobile */
  }
`;

const PaginationInfo = styled.span`
  font-size: 14px;
  font-weight: bold;
  color: #8A3518;
  margin: 5px 10px 0;

  @media (max-width: 768px) {
    font-size: 13px; /* Adjust font size for mobile */
    margin: 5px 5px 0; /* Adjust margin */
  }
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
  color: #8A3518;
  transition: color 0.3s;

  &:hover {
    color: #c0392b;
  }

  @media (max-width: 768px) {
    font-size: 18px; /* Adjust font size for mobile */
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px; 
`;

const TrashIcon = styled.div`
  cursor: pointer;
  color: #D3D3D3;
  transition: color 0.3s ease;

  &:hover {
    color: #D22B2B;
  }
`;

const UndoIcon = styled.div`
  cursor: pointer;
  color: #D3D3D3;
  transition: color 0.3s ease;

  &:hover {
    color: #818589;
  }
`;

const NotificationsModal = ({
  mentions = [],
  followers = [],
  invites = [],
  sistema = [],
  read = [],
  trashed = [],
  onClose,
  userId,
  fetchNotifications
}) => {
  const [activeTab, setActiveTab] = useState('all');
  const [currentPage, setCurrentPage] = useState(1);
  const [isClosing, setIsClosing] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [selectedNotification, setSelectedNotification] = useState(null);

  const notificationsPerPage = 4;
  const allNotifications = [...mentions, ...followers, ...invites, ...sistema];
  const modalRef = useRef(null);

  useEffect(() => {
    setCurrentPage(1);
  }, [activeTab]);

  useEffect(() => {
    if (modalRef.current) {
      modalRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [currentPage]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const moveToTrash = (notificationId) => {
    axios.post(`https://api.hikikorp.it/notifications/${userId}/trash`, {
      notificationId: notificationId,
    })
      .then((response) => {
        fetchNotifications();
      })
      .catch((error) => {
        console.error('Error moving notification to trash:', error);
      });
  };

  const handleDeleteClick = (notification) => {
    setSelectedNotification(notification);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setSelectedNotification(null);
  };

  const handleModalConfirmDelete = () => {
    if (selectedNotification) {
      deleteNotification(selectedNotification.id);
    }
    handleModalClose();
  };

  const deleteNotification = (notificationId) => {
    axios.post(`https://api.hikikorp.it/notifications/${userId}/delete`, {
      notificationId: notificationId,
    })
      .then((response) => {
        fetchNotifications();
      })
      .catch((error) => {
        console.error('Error deleting notification:', error);
      });
  };

  const restoreNotification = (notificationId) => {
    axios.post(`https://api.hikikorp.it/notifications/${userId}/restore`, {
      notificationId: notificationId,
    })
      .then((response) => {
        const title = response.data.title;
        toast.success(`Notifica "${title}" ripristinata con successo!`);
        fetchNotifications();
      })
      .catch((error) => {
        console.error('Error restoring notification:', error);
        toast.error('Errore durante il ripristino della notifica.');
      });
  };

  const paginatedNotifications = (notifications) => {
    const sortedNotifications = [...notifications].sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
    const startIndex = (currentPage - 1) * notificationsPerPage;
    return sortedNotifications.slice(startIndex, startIndex + notificationsPerPage);
  };

  const renderNotifications = () => {
    const formatTimestamp = (timestamp) => {
      const daysOfWeek = ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'];
      const date = new Date(timestamp);
      const today = new Date();
      const yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);

      const isToday = date.toDateString() === today.toDateString();
      const isYesterday = date.toDateString() === yesterday.toDateString();
      const formattedTime = date.toLocaleString('it-IT', { hour: '2-digit', minute: '2-digit' });
      const formattedDate = date.toLocaleString('it-IT', { day: '2-digit', month: '2-digit', year: 'numeric' });

      if (isToday) return `Oggi, ${formattedDate}, ${formattedTime}`;
      if (isYesterday) return `Ieri, ${formattedDate}, ${formattedTime}`;

      return `${daysOfWeek[date.getDay()]}, ${formattedDate}, ${formattedTime}`;
    };

    const getNotificationType = (notification) => {
      if (notification.type) {
        return notification.type === 'mention' ? 'Menzione' :
          notification.type === 'follower' ? 'Followers' :
            notification.type === 'sistema' ? 'Sistema' :
              notification.type === 'invite' ? 'Invito' : 'Sconosciuto';
      }
      return "Sconosciuto";
    };

    const renderList = (list) => {
      if (activeTab === 'trashed' && list.length === 0) {
        return <NoNotifications>Non hai notifiche cestinate!</NoNotifications>;
      }

      return list.length > 0
        ? paginatedNotifications(list).map((notification, index) => {
          const isTrashedTab = activeTab === 'trashed';

          return (
            <NotificationItem key={index}>
              <ProfileImage src={notification.profilePhoto} alt="Profile" />
              <NotificationContent>
                <NotificationHeader>
                  <NotificationText>
                    <strong>{notification.title}</strong> ({getNotificationType(notification)})
                  </NotificationText>

                  <IconContainer>
                    {isTrashedTab && (
                      <UndoIcon onClick={() => restoreNotification(notification.id)}>
                        <FaUndo />
                      </UndoIcon>
                    )}

                    <TrashIcon onClick={() => {
                      if (isTrashedTab) {
                        handleDeleteClick(notification);
                      } else {
                        moveToTrash(notification.id);
                      }
                    }}>
                      <FaTrash />
                    </TrashIcon>
                  </IconContainer>
                </NotificationHeader>

                <NotificationText>{notification.message}</NotificationText>
                <Timestamp>{formatTimestamp(notification.timestamp)}</Timestamp>
              </NotificationContent>
            </NotificationItem>
          );
        })
        : <NoNotifications>Non hai nuove notifiche!</NoNotifications>;
    };

    switch (activeTab) {
      case 'mentions': return renderList(mentions);
      case 'followers': return renderList(followers);
      case 'invites': return renderList(invites);
      case 'sistema': return renderList(sistema);
      case 'read': return renderList(read);
      case 'trashed': return renderList(trashed);
      case 'all':
      default: return renderList(allNotifications);
    }
  };

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsModalOpen(false); // Close modal, hiding overlay
      onClose();
    }, 400);
    fetchNotifications();
  };

  const handleMarkAllRead = () => {
    if (allNotifications.length === 0) return;

    fetch(`https://api.hikikorp.it/notifications/${userId}/read`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ mentions, followers, invites }),
    })
      .then((response) => response.json())
      .then(() => fetchNotifications())
      .catch((error) => console.error('Error marking notifications as read:', error));

    setIsClosing(true);
    setTimeout(onClose, 400);
  };

  const totalNotifications = () => {
    switch (activeTab) {
      case 'mentions': return mentions.length;
      case 'followers': return followers.length;
      case 'invites': return invites.length;
      case 'sistema': return sistema.length;
      case 'read': return read.length;
      case 'trashed': return trashed.length;
      case 'all':
      default: return allNotifications.length;
    }
  };

  const totalPages = Math.ceil(totalNotifications() / notificationsPerPage);

  return (
    <>
      {/* Render the overlay only if modal is open */}
      <Overlay isModalOpen={isModalOpen} />

      <NotificationsModalContainer isClosing={isClosing} ref={modalRef}>
        <Header>
          <Title>Notifiche</Title>
          <MarkAllRead disabled={allNotifications.length === 0} onClick={handleMarkAllRead}>
            <FaCheckDouble /> Segna tutto come letto
          </MarkAllRead>
          <CloseButton onClick={handleClose}>
            <FaTimes />
          </CloseButton>
        </Header>

        <TabsContainer>
          <Tab active={activeTab === 'all'} onClick={() => setActiveTab('all')}>Tutte<TabCount active={activeTab === 'all'}>{allNotifications.length}</TabCount></Tab>
          <Tab active={activeTab === 'mentions'} onClick={() => setActiveTab('mentions')}>Menzioni<TabCount active={activeTab === 'mentions'}>{mentions.length}</TabCount></Tab>
          <Tab active={activeTab === 'followers'} onClick={() => setActiveTab('followers')}>Followers<TabCount active={activeTab === 'followers'}>{followers.length}</TabCount></Tab>
          <Tab active={activeTab === 'invites'} onClick={() => setActiveTab('invites')}>Inviti<TabCount active={activeTab === 'invites'}>{invites.length}</TabCount></Tab>
          <Tab active={activeTab === 'sistema'} onClick={() => setActiveTab('sistema')}>Sistema<TabCount active={activeTab === 'sistema'}>{sistema.length}</TabCount></Tab>
          <Tab active={activeTab === 'read'} onClick={() => setActiveTab('read')}>Lette<TabCount active={activeTab === 'read'}>{read.length}</TabCount></Tab>
          <Tab active={activeTab === 'trashed'} onClick={() => setActiveTab('trashed')}>Cestino<TabCount active={activeTab === 'trashed'}>{trashed.length}</TabCount></Tab>
        </TabsContainer>

        {renderNotifications()}

        {showModal && (
          <DeleteNotificationModal
            onCancel={handleModalClose}
            onConfirmDelete={handleModalConfirmDelete}
            notificationTitle={selectedNotification?.title}
          />
        )}

        {totalPages > 0 && (
          <PaginationContainer>
            <PageButton
              disabled={currentPage === 1 || totalNotifications() <= notificationsPerPage}
              onClick={() => handlePageChange(currentPage - 1)}>
              ←
            </PageButton>
            <PaginationInfo>
              {totalPages === 0 ? 0 : currentPage} / {totalPages === 0 ? 0 : totalPages}
            </PaginationInfo>
            <PageButton
              disabled={currentPage === totalPages || totalNotifications() <= notificationsPerPage}
              onClick={() => handlePageChange(currentPage + 1)}>
              →
            </PageButton>
          </PaginationContainer>
        )}
      </NotificationsModalContainer>
    </>
  );
};

export default NotificationsModal;