import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import Countdown from './countdown';
import CloseIcon from '@mui/icons-material/Close';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.9);
  }
`;

const blurIn = keyframes`
  from {
    backdrop-filter: blur(0px);
  }
  to {
    backdrop-filter: blur(8px);
  }
`;

const blurOut = keyframes`
  from {
    backdrop-filter: blur(8px);
  }
  to {
    backdrop-filter: blur(0px);
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: ${(props) => (props.isClosing ? 'blur(0px)' : 'blur(8px)')};
  animation: ${(props) => (props.isClosing ? blurOut : blurIn)} 0.5s ease;
`;

const ModalContent = styled.div`
  background-color: #F5F5F5;
  padding: 25px 45px; 
  border-radius: 16px; 
  text-align: center;
  border: 5px solid #8B3419; 
  animation: ${(props) => (props.isClosing ? fadeOut : fadeIn)} 0.5s ease;
  position: relative; 

  max-width: 600px; 
  max-height: 90vh; 
  width: 100%; 
  height: auto;

  @media screen and (max-width: 768px) {
    width: 90vw; 
    height: 80vh; 
    padding: 20px;
    border: 5px solid #5D3FD3; 
    overflow-y: auto;
  }

  ::-webkit-scrollbar {
    width: 2px; 
  }

  ::-webkit-scrollbar-track {
    background: #7F00FF;
    border-radius: 5px; 
  }

  ::-webkit-scrollbar-thumb {
    background-color: #5D3FD3;
    border-radius: 5px;
    border: 2px solid #7F00FF; 
  }

  scrollbar-color: #5D3FD3 #7F00FF;
  scrollbar-width: thin; 
`;

const StyledHeading = styled.h2`
  font-family: 'Poppins', sans-serif;
  color: #8B3419;
  font-weight: bold;
  font-size: 36px; 
  margin-bottom: 20px;
  margin-top: 0; 

  @media screen and (max-width: 768px) {
    color: #5D3FD3;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  position: absolute;
  top: 10px; 
  right: 10px;
  cursor: pointer;
  font-size: 20px;  
  color: #8B3419;
  transition: all 0.3s ease;

  &:hover {
    color: #b64421;
  }

  &:focus {
    outline: none;
  }

  @media screen and (max-width: 768px) {
    color: #5D3FD3;

  &:hover {
    color: #7F00FF;
  }

  &:focus {
    outline: none
  }
`;

const CountdownModal = ({ onClose }) => {
  const [isClosing, setIsClosing] = useState(false);

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      onClose();
    }, 500);
  };

  return (
    <ModalOverlay isClosing={isClosing}>
      <ModalContent isClosing={isClosing}>
        <CloseButton onClick={handleClose}>
          <CloseIcon />
        </CloseButton>
        <StyledHeading>Countdown</StyledHeading>
        <Countdown />
      </ModalContent>
    </ModalOverlay>
  );
};

export default CountdownModal;