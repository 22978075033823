import React, { useState } from 'react';
import Button from '@mui/material/Button';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CountdownModal from '../countdownModal.jsx';
import styled, { createGlobalStyle } from 'styled-components';
import AdminPanel from '../adminPanel/adminPanelBtn.jsx';

const GlobalStyle = createGlobalStyle`
  html, body {
    font-family: 'Poppins', sans-serif;
    margin: 0;
    padding: 0;
    height: 100vh;  
    overflow: hidden; 
  }
`;

const BackgroundVideo = styled.video`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; 
  z-index: -1;
  pointer-events: none; 

  @media (max-width: 768px) {
    content: url('/mobileVideo.mp4');
  }
`;

const BackgroundImage = styled.img`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;

  @media (max-width: 768px) {
    display: none;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;  
  position: relative;
  margin-top: 20px;
  width: 100%;
  gap: 20px; 
`;

const AdminPanelWrapper = styled.div`
  display: flex;
  align-items: center; 
`;

const HomePage = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            {/* Global Styles */}
            <GlobalStyle />

            <BackgroundVideo
                src="/mobileVideo.mp4"
                autoPlay
                loop
                muted
                playsInline
            />

            {/* Background Image */}
            <BackgroundImage src='/background.jpg' alt="Background" />

            <ButtonWrapper>
                <Button
                    variant="contained"
                    onClick={openModal}
                    sx={{
                        backgroundColor: '#8A3518',
                        borderRadius: '8px',
                        fontFamily: 'Poppins',
                        fontSize: '16px',
                        padding: '12px 24px',
                        height: '48px',  
                        display: 'flex',
                        alignItems: 'center',
                        '&:hover': {
                            backgroundColor: '#722c15',
                        },

                        '@media (max-width: 768px)': {
                            backgroundColor: '#5D3FD3',
                            marginLeft: '20px', 
                            '&:hover': {
                                backgroundColor: '#7F00FF'
                            },
                            '&:focus': {
                                backgroundColor: '#7F00FF'
                            }
                        },
                    }}
                    startIcon={<AccessTimeIcon />}
                >
                    Countdown
                </Button>

                <AdminPanelWrapper>
                    <AdminPanel />
                </AdminPanelWrapper>
            </ButtonWrapper>

            {isModalOpen && <CountdownModal onClose={closeModal} />}
        </>
    );
};

export default HomePage;
