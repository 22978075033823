import React, { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import styled, { createGlobalStyle } from 'styled-components';
import DashboardIcon from '@mui/icons-material/Dashboard';
import BarChartIcon from '@mui/icons-material/BarChart';
import ChatIcon from '@mui/icons-material/Chat';
import LanguageIcon from '@mui/icons-material/Language';
import GroupIcon from '@mui/icons-material/Group';
import SettingsIcon from '@mui/icons-material/Settings';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import 'react-calendar/dist/Calendar.css';
import { it } from 'date-fns/locale';
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import { toast } from 'react-hot-toast';

const SideBar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [date, setDate] = useState(new Date());
  const [activeItem, setActiveItem] = useState('Dashboard');
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleDateChange = (newDate) => {
    setDate(newDate);
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const getPageTitle = (pathname) => {
    switch (pathname) {
      case '/pannelloadmin':
        return 'HIKIKO STORE | Pannello Admin';
      default:
        return 'HIKIKO STORE';
    }
  };

  useEffect(() => {
    document.title = getPageTitle(location.pathname);
  }, [location]);

  const handleLogout = async () => {
    try {
      const response = await fetch('https://api.hikikorp.it/logout', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token: localStorage.getItem('token') }),
      });

      if (response.status === 200) {

        toast.success('Logout effettuato con successo! Reindirizzando...');

        localStorage.removeItem('token');

        setTimeout(() => {
          navigate('/');
          window.location.reload();
        }, 2500);
      } else {
        const errorData = await response.json();
        toast.error(`Logout fallito: ${errorData.error}`);
      }
    } catch (error) {
      toast.error('Errore del server durante il logout!');
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsCollapsed(true); // Always collapsed on mobile
      }
    };

    handleResize(); // Call it initially to apply on load
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const menuItems = [
    { id: 'Dashboard', icon: <DashboardIcon />, label: 'Dashboard' },
    { id: 'Statistiche', icon: <BarChartIcon />, label: 'Statistiche' },
    { id: 'Messaggi', icon: <ChatIcon />, label: 'Messaggi' },
    { id: 'Lingua', icon: <LanguageIcon />, label: 'Lingua' },
    { id: 'Team', icon: <GroupIcon />, label: 'Team' },
    { id: 'cPanel', icon: <SettingsIcon />, label: 'cPanel', url: 'https://cpanel.hikikorp.it/' },
  ];

  const toggleSidebar = () => {
    if (window.innerWidth > 768) {
      setIsCollapsed(!isCollapsed);
    }
  };

  return (
    <>
      <Helmet>
        <title>{getPageTitle(location.pathname)}</title>
      </Helmet>

      <GlobalStyle />
      <SidebarContainer isCollapsed={isCollapsed}>
        <LogoSection isCollapsed={isCollapsed}>
          <Logo isCollapsed={isCollapsed}>
            <img src="/hikiko.png" alt="Logo" />
          </Logo>
          <TextWrapper>
            {!isCollapsed && (
              <TitleSection>
                <Title>HIKIKO STORE</Title>
                <SubTitle>Pannello Admin</SubTitle>
              </TitleSection>
            )}
            <CollapseButton onClick={toggleSidebar} isCollapsed={isCollapsed}>
              {isCollapsed ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </CollapseButton>
          </TextWrapper>
        </LogoSection>

        {!isCollapsed && (
          <StyledCalendar
            value={date}
            onChange={handleDateChange}
            locale={it}
            navigationLabel={({ date, label }) => {
              const parts = label.split(' ');
              return `${capitalizeFirstLetter(parts[0])} ${parts[1]}`;
            }}
            formatMonthYear={(locale, date) =>
              capitalizeFirstLetter(date.toLocaleDateString('it-IT', { month: 'long', year: 'numeric' }))
            }
            prev2Label={null}
            next2Label={null}
            onClickMonth={null}
            onClickYear={null}
            formatShortWeekday={(locale, date) => {
              const day = date.getDay();
              return day === 0 ? '' : date.toLocaleDateString('it-IT', { weekday: 'short' });
            }}
          />
        )}

        <Menu isCollapsed={isCollapsed}>
          {menuItems.map((item) => (
            <MenuItem
              key={item.id}
              active={activeItem === item.id}
              onClick={() => {
                if (item.url) {
                  window.open(item.url, '_blank');
                } else {
                  setActiveItem(item.id);
                }
              }}
              isCollapsed={isCollapsed}
            >
              {item.icon}
              {!isCollapsed && <span>{item.label}</span>}
            </MenuItem>
          ))}
        </Menu>

        <LogoutButton onClick={handleLogout} isCollapsed={isCollapsed}>
          <LogoutIcon style={{ marginRight: isCollapsed ? '0' : '10px' }} />
          {!isCollapsed && <span>LOGOUT</span>}
        </LogoutButton>
      </SidebarContainer>
    </>
  );
};

export default SideBar;

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

  html,
  body {
    height: 100%;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
`;

const SidebarContainer = styled.div`
  width: ${({ isCollapsed }) => (isCollapsed ? '80px' : '250px')};
  height: 100vh;
  background-color: #f9faff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  z-index: 1;
  transition: width 0.5s ease; 
  
    @media (max-width: 768px) {
      background-color: white;
  }
`;

const LogoSection = styled.div`
  display: flex;
  justify-content: center; /* Center the logo horizontally */
  align-items: center; /* Center the logo vertically */
  height: ${({ isCollapsed }) => (isCollapsed ? '80px' : 'auto')}; 
  margin-bottom: ${({ isCollapsed }) => (isCollapsed ? '20px' : '0')}; 
  width: 100%;
  transition: all 0.3s ease;
`;

const Logo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: ${({ isCollapsed }) => (isCollapsed ? '10px' : '0')}; 
  img {
    width: ${({ isCollapsed }) => (isCollapsed ? '60px' : '50px')}; 
    height: ${({ isCollapsed }) => (isCollapsed ? '60px' : '50px')}; 
    transition: all 0.3s ease;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
`;

const TitleSection = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h1`
  font-size: 20px;
  color: #E5833A;
  font-weight: bold;
  margin: 0;
  padding-bottom: 5px;
  text-shadow: 
    0px 2px 2px rgba(0, 0, 0, 0.2),
    0px 2px 2px rgba(255, 134, 89, 0.4);
  -webkit-text-stroke: 0.25px #8B3419;
  text-stroke: 0.25px #8B3419;
`;

const SubTitle = styled.h2`
  font-size: 14px;
  color: #8B3419;
  margin: 0;
  text-shadow: 
    0px 1px 1px rgba(0, 0, 0, 0.2),
    0px 1px 1px rgba(255, 134, 89, 0.4);
  -webkit-text-stroke: 0.25px #E5833A;
  text-stroke: 0.25px #E5833A;
`;

const CollapseButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 5px; 
  background-color: #f1c6a6;
  border-radius: 50%;
  width: 25px; 
  height: 25px; 
  transition: background-color 0.3s ease;
  position: absolute; 
  right: ${({ isCollapsed }) => (isCollapsed ? '-40px' : '-40px')};
  top: ${({ isCollapsed }) => (isCollapsed ? '-20px' : '0')}; 
  margin-top: 5px;

  &:hover {
    background-color: #f5d4bb;
  }

  svg {
    font-size: 1rem; 
    color: #8B3419;
  }

  @media (max-width: 768px) {
    display: none;  /* Hide the button on mobile screens */
  }
`;

const Menu = styled.ul`
  list-style: none;
  padding: 0;
  margin: ${({ isCollapsed }) => (isCollapsed ? '40px 0 0 0' : '0')};
  display: flex;
  flex-direction: column;
  align-items: ${({ isCollapsed }) => (isCollapsed ? 'center' : 'flex-start')}; 
  width: 100%;
`;


const MenuItem = styled.li`
  display: flex;
  justify-content: ${({ isCollapsed }) => (isCollapsed ? 'center' : 'flex-start')}; 
  align-items: center;
  padding: ${({ isCollapsed }) => (isCollapsed ? '10px' : '10px 15px')}; 
  cursor: pointer;
  color: ${({ active }) => (active ? '#8B3419' : '#333')};
  font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
  background-color: ${({ active }) => (active ? '#f1c6a6' : 'transparent')};
  border-radius: ${({ isCollapsed }) => (isCollapsed ? '8px' : '10px')}; 
  width: ${({ isCollapsed }) => (isCollapsed ? '40px' : 'calc(100% - 10px)')}; 
  height: ${({ isCollapsed }) => (isCollapsed ? '40px' : 'auto')}; 
  position: relative;
  transition: background-color 0.3s ease-in-out, width 0.3s ease-in-out;

  &:hover {
    background-color: ${({ active }) => (active ? '#f1c6a6' : '#f5d4bb')};
  }

  svg {
    margin-right: ${({ isCollapsed }) => (isCollapsed ? '0' : '10px')}; 
    font-size: 20px; /* Icon size */
  }

  span {
    font-size: 16px;
    display: ${({ isCollapsed }) => (isCollapsed ? 'none' : 'inline')}; 
  }

  &:before {
    content: '';
    position: absolute;
    right: 0;
    width: 6.5px;
    height: 100%;
    background-color: ${({ active, isCollapsed }) =>
    active && !isCollapsed ? '#8B3419' : 'transparent'};
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    transition: background-color 0.3s ease-in-out;
  }
`;


const StyledCalendar = styled(Calendar)`
  display: ${({ isCollapsed }) => (isCollapsed ? 'none' : 'block')};
  border: 2px solid #8A3518; 
  background-color: #fff;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 10px;
  font-family: 'Poppins', sans-serif;
  font-size: 0.75rem;
  transition: all 0.5s ease; 
  margin-bottom: 100px;
  margin-top: 50px;

  abbr {
    text-decoration: none;
  }

  .react-calendar__tile--active {
    background-color: #e5833a !important;
    color: white;
    border-radius: 5px;
    width: 25px;
    height: 25px;
  }

  .react-calendar__navigation__label {
    pointer-events: none; 
    cursor: default;      
  }

  .react-calendar__navigation button {
    font-weight: bold;
    color: #333;
    margin: 0;
    padding: 5px;
    font-family: 'Poppins', sans-serif;
    font-size: 0.75rem;
  }

  .react-calendar__tile {
    height: 25px;
    max-width: 40px;
    text-align: center;
    margin: 2px;
    border-radius: 8px;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
  }
  
  .react-calendar__tile:disabled {
    background-color: #f0f0f0;
  }

  .react-calendar__month-view__weekdays {
    display: flex;
    justify-content: space-between; 
    text-align: center;
    color: #333;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    font-size: 0.75rem;
  }

  .react-calendar__month-view__days {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; 
  }

  .react-calendar__month-view__days__day {
    text-align: center;
    flex-basis: calc(100% / 6 - 2px); 
    font-size: 0.7rem;
  }

  .react-calendar__month-view__weekdays__weekday:nth-child(7),
  .react-calendar__month-view__days__day:nth-child(7n) {
    display: none;
  }
`;

const LogoutButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center; 
  padding: 10px 20px;
  background-color: #8A3518; 
  border: none;
  border-radius: 10px;
  color: white;
  font-weight: bold;
  font-size: 1rem;
  cursor: pointer;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
  margin-top: auto;
  transition: background-color 0.3s ease;
  margin-bottom: 60px;
  width: 100%;

  &:hover {
    background-color: #722c15; 
  }

  svg {
    font-size: 1.5rem;
  }

  span {
    display: ${({ isCollapsed }) => (isCollapsed ? 'none' : 'inline')};
  }
`;