import React, { useState, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.9);
  }
`;

const blurIn = keyframes`
  from {
    backdrop-filter: blur(0px);
  }
  to {
    backdrop-filter: blur(8px);
  }
`;

const blurOut = keyframes`
  from {
    backdrop-filter: blur(8px);
  }
  to {
    backdrop-filter: blur(0px);
  }
`;

const AdminPanelContainer = styled.div`
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
`;

const AdminButton = styled.button`
  background-color: #9B870C;
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #DAA520;
  }

  svg {
    margin-right: 8px; 
  }

  /* Center icon on mobile */
  @media screen and (max-width: 768px) {
    background-color: #5D3FD3; 
    justify-content: center; 
    padding: 12px; 

  &:hover {
    background-color: #7F00FF;
  }
    
    svg {
      margin-right: 0; 
    }
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: ${(props) => (props.isClosing ? 'blur(0px)' : 'blur(8px)')};
  animation: ${(props) => (props.isClosing ? blurOut : blurIn)} 0.5s ease;
`;

const ModalContent = styled.div`
  background-color: #F5F5F5;
  padding: 25px 20px;
  border-radius: 16px; 
  max-width: 400px;
  width: 100%;
  text-align: center;
  border: 5px solid #9B870C;
  animation: ${(props) => (props.isClosing ? fadeOut : fadeIn)} 0.5s ease;
  position: relative;

  @media screen and (max-width: 768px) {
    border: 5px solid #5D3FD3;
`;

const StyledHeading = styled.h2`
  font-family: 'Poppins', sans-serif;
  color: #9B870C;
  font-weight: bold;
  font-size: 28px;
  margin-bottom: 20px;
  margin-top: 0;

  @media screen and (max-width: 768px) {
    color: #5D3FD3;
`;

const InputField = styled.input`
  width: calc(100% - 20px);
  padding: 12px;
  margin-top: 20px;
  border-radius: 8px;
  border: 2px solid #9B870C;
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  outline: none;
  box-sizing: border-box;

  @media screen and (max-width: 768px) {
    border: 2px solid #5D3FD3;
`;

const SubmitButton = styled.button`
  background-color: #9B870C;
  border: none;
  border-radius: 8px;
  padding: 8px 16px;
  font-size: 16px;
  color: white;
  font-family: 'Poppins', sans-serif;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  transition: all 0.3s ease;
  margin-top: 20px;
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};

  &:hover {
    background-color: ${(props) => (props.disabled ? '#9B870C' : '#DAA520')};
  }

  @media screen and (max-width: 768px) {
    background-color: #5D3FD3;

  &:hover {
    background-color: ${(props) => (props.disabled ? '#5D3FD3' : '#7F00FF')};
  }
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  font-size: 24px;
  color: #9b870c;
  transition: all 0.3s ease;

  &:hover {
    color: #daa520;
  }

  &:focus {
    outline: none;
  }

  @media screen and (max-width: 768px) {
    color: #5D3FD3;

  &:hover {
    color: #7F00FF;
  }

  &:focus {
    outline: none;
  }
`;

const OTPContainer = styled.div`
  display: flex;
  justify-content: center; 
  align-items: center;
  gap: 10px; 
  margin-top: 20px;
`;

const OTPInput = styled.input`
  width: 40px;
  height: 50px;
  text-align: center;
  font-size: 20px;
  border: 2px solid #9B870C;
  border-radius: 8px;
  outline: none;
  transition: border-color 0.2s ease-in-out;

  &:focus {
    border-color: #DAA520;
  }

  @media screen and (max-width: 768px) {
    border: 2px solid #5D3FD3;

    &:focus {
      border-color: #7F00FF;
    }
  }
`;

const StyledNewOTPLink = styled.a`
  color: #9B870C; 
  font-weight: bold;

  @media (max-width: 768px) {
    color: #5D3FD3; 

    &:focus {
      color: #7F00FF;
    }

    &:hover {
      color: #7F00FF;
    }
  }
`;

const AdminPanelBtn = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [isOTPModalOpen, setIsOTPModalOpen] = useState(false);
  const [isClosingOTP, setIsClosingOTP] = useState(false);
  const [adminCode, setAdminCode] = useState('');
  const [otpCode, setOtpCode] = useState(['', '', '', '', '', '']);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const otpRefs = useRef([]);

  const openModal = () => setIsModalOpen(true);

  const closeModal = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsModalOpen(false);
      setAdminCode('');
      setIsClosing(false);
    }, 500);
  };

  const closeOTPModal = () => {
    setIsClosingOTP(true);
    setTimeout(() => {
      setIsOTPModalOpen(false);
      setOtpCode(['', '', '', '', '', '']);
      setAdminCode('');
      setIsClosingOTP(false);
    }, 500);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setAdminCode(value);
    }
  };

  const isMobile = useMediaQuery('(max-width:768px)');

  const handleOTPInput = (index, e) => {
    const { value } = e.target;

    if (/^\d$/.test(value) || value === '') {
      const newOtp = [...otpCode];
      newOtp[index] = value;
      setOtpCode(newOtp);

      if (value !== '' && index < otpRefs.current.length - 1) {
        otpRefs.current[index + 1].focus();
      }
    }
  };

  const handleBackspace = (index, e) => {
    if (e.key === 'Backspace' && otpCode[index] === '' && index > 0) {
      otpRefs.current[index - 1].focus();
    }
  };

  const handleAdminSubmit = async () => {
    setIsSubmitting(true);
    try {
      const response = await fetch('https://api.hikikorp.it/admin-code', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ code: adminCode }),
      });

      if (response.status === 200) {
        toast.success('Successo! Generando il codice OTP...');

        setTimeout(async () => {

          const otpResponse = await fetch('https://api.hikikorp.it/generate-otp', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ code: adminCode }),
          });

          if (otpResponse.status === 200) {
            toast.success('Successo! Inserisci il codice OTP inviato alla tua email.');
            setIsOTPModalOpen(true);
            setIsModalOpen(false);
          } else {
            const otpData = await otpResponse.json();
            toast.error(`Errore: ${otpData.error}`);
          }

          setIsSubmitting(false);
        }, 3000);

      } else if (response.status === 401) {
        toast.error('Codice non valido!');
        setAdminCode('');
        setIsSubmitting(false);
      } else {
        toast.error('Errore inaspettato del server!');
        setIsSubmitting(false);
      }
    } catch (error) {
      toast.error('Errore del server!');
      setIsSubmitting(false);
    }
  };

  const handleGenerateNewOTP = async () => {

    setIsSubmitting(true);
    
    try {

      const otpResponse = await fetch('https://api.hikikorp.it/generate-otp', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ code: adminCode }),
      });

      if (otpResponse.status === 200) {
        toast.success('Successo! Inserisci il codice OTP inviato alla tua email.');
        setIsOTPModalOpen(true);
        setIsModalOpen(false);
      } else {
        const otpData = await otpResponse.json();
        toast.error(`Errore: ${otpData.error}`);
      }

      setIsSubmitting(false);
    } catch (error) {
      toast.error('Errore del server! Impossibile generare un nuovo OTP.');
      setIsSubmitting(false);
    }
  };


  const handleOTPSubmit = async () => {
    if (otpCode.join('').length === 6) {
      setIsSubmitting(true);
      try {
        const otpResponse = await fetch('https://api.hikikorp.it/verify-otp', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ code: adminCode, otpCode: otpCode.join('') }),
        });
  
        if (otpResponse.status === 200) {
          const data = await otpResponse.json();
  
          if (data.token) {
            toast.success('OTP verificato! Reindirizzando...');
            
            localStorage.setItem('token', data.token);
  
            setTimeout(() => {
              navigate('/pannelloadmin');
            }, 2000);
          } else {
            toast.error('Token non ricevuto dalla risposta!');
            setIsSubmitting(false);
          }
        } else {
          toast.error('Codice OTP non valido o scaduto!');
          setIsSubmitting(false);
        }
      } catch (error) {
        toast.error('Errore del server durante la verifica del codice OTP!');
        setIsSubmitting(false);
      }
    } else {
      toast.error('Per favore inserisci il codice OTP!');
    }
  };
  


  return (
    <AdminPanelContainer>
      <AdminButton onClick={openModal}>
        <AdminPanelSettingsIcon />
        {!isMobile && 'Admin'}
      </AdminButton>

      {/* Admin Code Modal */}
      {isModalOpen && (
        <ModalOverlay isClosing={isClosing}>
          <ModalContent isClosing={isClosing}>
            <CloseButton onClick={closeModal}><CloseIcon /></CloseButton>
            <StyledHeading>Autenticazione</StyledHeading>
            <InputField
              type="text"
              placeholder="Es: 123456"
              value={adminCode}
              maxLength={6}
              onChange={handleInputChange}
              disabled={isSubmitting}
              style={{ textAlign: 'center' }}
            />

            <SubmitButton
              onClick={handleAdminSubmit}
              disabled={!adminCode || isSubmitting}
            >
              {isSubmitting ? 'Caricamento...' : 'Conferma'}
            </SubmitButton>

          </ModalContent>
        </ModalOverlay>
      )}

      {/* OTP Modal */}
      {isOTPModalOpen && (
        <ModalOverlay isClosing={isClosingOTP}>
          <ModalContent isClosing={isClosingOTP}>
            <CloseButton onClick={closeOTPModal}><CloseIcon /></CloseButton>
            <StyledHeading>Verifica OTP</StyledHeading>
            <p>Per favore inserisci il codice OTP inviato alla tua email:</p>
            <OTPContainer>
              {otpCode.map((digit, index) => (
                <OTPInput
                  key={index}
                  type="text"
                  value={digit}
                  maxLength="1"
                  onChange={(e) => handleOTPInput(index, e)}
                  onKeyDown={(e) => handleBackspace(index, e)}
                  ref={(el) => (otpRefs.current[index] = el)}
                  disabled={isSubmitting}
                />
              ))}
            </OTPContainer>
            <SubmitButton
              onClick={handleOTPSubmit}
              disabled={isSubmitting || otpCode.join('').length !== 6}
            >
              {isSubmitting ? 'Caricamento...' : 'Conferma'}
            </SubmitButton>

            {/* Link to request a new OTP */}
            <p>
              Non hai ricevuto nessun codice? <br />
              <StyledNewOTPLink
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  handleGenerateNewOTP();
                }}
              >
                Richiedi un nuovo codice OTP
              </StyledNewOTPLink>
            </p>
          </ModalContent>
        </ModalOverlay>
      )}

    </AdminPanelContainer>
  );
};

export default AdminPanelBtn;