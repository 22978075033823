export const validateToken = async (token) => {
  if (!token) return false; 

  try {
    const response = await fetch('https://api.hikikorp.it/validate-token', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`, 
      },
    });

    if (response.status === 200) {
      return true;

    } else {
      
      localStorage.removeItem('token');
      return false; 
    }
  } catch (error) {
    console.error('Token validation error:', error);
    return false; 
  }
};