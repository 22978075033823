import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import NotFound from './_components/_pages/notFound.jsx';
import HomePage from './_components/_pages/homePage.jsx';
import AdminPage from './_components/_pages/admin/adminPage.jsx';
import { Toaster } from 'react-hot-toast';
import ProtectedRoute from './auth/protectedRoute.jsx';

const App = () => {
  return (
    <>
      <div><Toaster /></div>

      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/pannelloadmin" element={<ProtectedRoute><AdminPage /></ProtectedRoute>} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;