import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { FaSearch, FaBell, FaEnvelope, FaGift } from 'react-icons/fa';
import SearchModal from './search/searchModal';
import NotificationsModal from './notifications/notificationsModal';
import GiftsModal from './gifts/giftsModal';

const TopBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 10px 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    padding: 10px; /* Reduce padding on mobile */
  }
`;

const Title = styled.h1`
  margin-left: 20px;
  font-size: 24px;
  color: #8A3518;

  @media (max-width: 768px) {
    display: none; /* Hide title on mobile */
  }
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  padding: 12px 12px;
  border-radius: 15px;
  border: 2px solid #8A3518;
  width: 400px;
  justify-content: space-between;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};

  @media (max-width: 768px) {
    display: none; /* Hide search bar on mobile */
  }
`;

const SearchIcon = styled(FaSearch)`
  color: #8A3518;
  margin-right: 8px;
  width: 20px;
`;


const SearchBar = styled.input`
  border: none;
  background-color: transparent;
  outline: none;
  width: 100%;
  padding: 0;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};

  &::placeholder {
    text-align: center;
    color: #8A3518;
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  }
`;

const CtrlK = styled.div`
  display: flex;
  align-items: center;
`;

const Keycap = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 4px 6px;
  margin-right: 4px;
  background-color: #e0e0e0;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 12px;
  font-weight: bold;
  color: #333;
`;

const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;

  @media (max-width: 768px) {
    margin-right: 0; /* Remove margin on mobile for better alignment */
  }
`;

const ProfileImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid #8A3518;
  margin-right: 10px;

    @media (max-width: 768px) {
    display: none;
  }
`;

const ProfileDetails = styled.div`
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: flex-start; /* Align text to the left */

  @media (max-width: 768px) {
    flex-direction: column; /* Ensure it stays in a column on mobile */
    align-items: flex-start; /* Align text to the left on mobile */
  }
`;

const UserName = styled.p`
  font-weight: bold;
  margin: 0;
  color: #8A3518;
  
  @media (max-width: 768px) {
    font-size: 14px;
    margin-right: 0;
  }
`;

const UserRole = styled.p`
  color: #E5833A;
  font-size: 12px;
  margin: 0;
  font-weight: 700;

  @media (max-width: 768px) {
    font-size: 10px;
    margin-top: 2px;
  }
`;

const IconButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${(props) => (props.hasGiftButton ? '-280px' : '-310px')};

  @media (max-width: 768px) {
    margin-left: 0px; /* Move the buttons slightly to the left on mobile */
  }
`;

const IconButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  margin: 0 5px;
  background-color: #F1C6A6; 
  border: none;
  border-radius: 10px;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  position: relative;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  opacity: ${props => (props.disabled ? 0.5 : 1)}; 

  &:hover {
    background-color: ${props => (props.disabled ? '#F1C6A6' : '#eebf92')};
  }

  svg {
    color: #8A3518;
    width: 20px;
    height: 20px;
  }

    &::after {
    content: '';
    position: absolute;
    top: -5px; 
    right: -5px; 
    width: 12px; 
    height: 12px;
    background-color: #E5833A; 
    border-radius: 50%;
    border: 2px solid white; 
    display: ${props => (props.hasNotifications ? 'block' : 'none')};
  }
;

  &.search-button {
    display: none;

    @media (max-width: 768px) {
      display: flex; /* Show search button on mobile */
    }
  }

  &.gift-button {
    display: flex; /* Hide gift button by default */

    @media (max-width: 768px) {
      display: none; /* Always show gift button on mobile */
    }
  }
`;

const Spinner = styled.div`
    border: 8px solid rgba(0, 0, 0, 0.1); /* Light border */
    border-top: 8px solid #8A3518; /* Blue */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
`;

const TopBar = () => {
  const [profilePic, setProfilePic] = useState('');
  const [username, setUsername] = useState('');
  const [role, setRole] = useState('');
  const [userId, setUserId] = useState('');
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const [isNotificationsModalOpen, setIsNotificationsModalOpen] = useState(false);
  const [isGiftsModalOpen, setIsGiftsModalOpen] = useState(false);
  const [hasNotifications, setHasNotifications] = useState(false);
  const [notifications, setNotifications] = useState({ mentions: [], followers: [], invites: [], read: [], trashed: [] });
  const [loading, setLoading] = useState(true);

  const fetchAdminData = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('Token not found');
        return;
      }

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.get('https://api.hikikorp.it/retrieve-admin-data', { headers });

      if (response.data) {
        const { id, name, profilePhoto, role } = response.data;
        setUsername(name);
        setProfilePic(profilePhoto);
        setRole(role);
        setUserId(id);
      }
    } catch (error) {
      console.error('Error fetching admin data:', error);
    }
  };

  const fetchNotifications = async () => {
    try {

      if (!userId) {
        return;
      }

      const token = localStorage.getItem('token');
      if (!token) {
        console.error('Token not found');
        return;
      }

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.get(`https://api.hikikorp.it/notifications/${userId}`, { headers });

      const { unread, read } = response.data.notifications;

      const mentions = unread.mentions.data.map(n => ({
        id: n.id,
        title: n.title,
        message: n.message,
        timestamp: n.createdAt,
        type: n.type,
        isTrashed: n.isTrashed || false,
        profilePhoto: n.profilePhoto || 'https://via.placeholder.com/40',
      }));

      const followers = unread.followers.data.map(n => ({
        id: n.id,
        title: n.title,
        message: n.message,
        timestamp: n.createdAt,
        type: n.type,
        isTrashed: n.isTrashed || false,
        profilePhoto: n.profilePhoto || 'https://via.placeholder.com/40',
      }));

      const invites = unread.invites.data.map(n => ({
        id: n.id,
        title: n.title,
        message: n.message,
        timestamp: n.createdAt,
        type: n.type,
        isTrashed: n.isTrashed || false,
        profilePhoto: n.profilePhoto || 'https://via.placeholder.com/40',
      }));

      const sistema = unread.sistema.data.map(n => ({
        id: n.id,
        title: n.title,
        message: n.message,
        timestamp: n.createdAt,
        type: n.type,
        isTrashed: n.isTrashed || false,
        profilePhoto: n.profilePhoto || 'https://cdn.discordapp.com/avatars/1282295274609315884/3bee6a5727544ba3448fcd1a51252296.png?size=1024',
      }));

      const readMentions = read.mentions.data
        .filter(n => !n.isTrashed)
        .map(n => ({
          id: n.id,
          title: n.title,
          message: n.message,
          timestamp: n.createdAt,
          type: n.type,
          profilePhoto: n.profilePhoto || 'https://via.placeholder.com/40',
        }))
        .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));

      const readFollowers = read.followers.data
        .filter(n => !n.isTrashed)
        .map(n => ({
          id: n.id,
          title: n.title,
          message: n.message,
          timestamp: n.createdAt,
          type: n.type,
          profilePhoto: n.profilePhoto || 'https://via.placeholder.com/40',
        }))
        .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));

      const readInvites = read.invites.data
        .filter(n => !n.isTrashed)
        .map(n => ({
          id: n.id,
          title: n.title,
          message: n.message,
          timestamp: n.createdAt,
          type: n.type,
          profilePhoto: n.profilePhoto || 'https://via.placeholder.com/40',
        }))
        .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));

      const readSistema = read.sistema.data
        .filter(n => !n.isTrashed)
        .map(n => ({
          id: n.id,
          title: n.title,
          message: n.message,
          timestamp: n.createdAt,
          type: n.type,
          profilePhoto: n.profilePhoto || 'https://cdn.discordapp.com/avatars/1282295274609315884/3bee6a5727544ba3448fcd1a51252296.png?size=1024',
        }))
        .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));

      const trashedMentions = read.mentions.data.filter(n => n.isTrashed).map(n => ({
        id: n.id,
        title: n.title,
        message: n.message,
        timestamp: n.createdAt,
        type: n.type,
        isTrashed: n.isTrashed || false,
        profilePhoto: n.profilePhoto || 'https://via.placeholder.com/40',
      }));

      const trashedFollowers = read.followers.data.filter(n => n.isTrashed).map(n => ({
        id: n.id,
        title: n.title,
        message: n.message,
        timestamp: n.createdAt,
        type: n.type,
        isTrashed: n.isTrashed || false,
        profilePhoto: n.profilePhoto || 'https://via.placeholder.com/40',
      }));

      const trashedInvites = read.invites.data.filter(n => n.isTrashed).map(n => ({
        id: n.id,
        title: n.title,
        message: n.message,
        timestamp: n.createdAt,
        type: n.type,
        isTrashed: n.isTrashed || false,
        profilePhoto: n.profilePhoto || 'https://via.placeholder.com/40',
      }));

      const trashedSistema = read.sistema.data.filter(n => n.isTrashed).map(n => ({
        id: n.id,
        title: n.title,
        message: n.message,
        timestamp: n.createdAt,
        type: n.type,
        isTrashed: n.isTrashed || false,
        profilePhoto: n.profilePhoto || 'https://cdn.discordapp.com/avatars/1282295274609315884/3bee6a5727544ba3448fcd1a51252296.png?size=1024',
      }));

      setNotifications({
        mentions,
        followers,
        invites,
        sistema,
        read: [...readMentions, ...readFollowers, ...readInvites, ...readSistema],
        trashed: [...trashedMentions, ...trashedFollowers, ...trashedInvites, ...trashedSistema]
      });

      setHasNotifications(
        mentions.length > 0 || followers.length > 0 || invites.length > 0 || sistema.length > 0
      );
    } catch (error) {
      console.error('Error fetching notifications:', error.response ? error.response.data : error.message);
      setNotifications({ mentions: [], followers: [], invites: [], sistema: [], read: [], trashed: [] });
      setHasNotifications(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchAdminData();
      await fetchNotifications();
      setLoading(false);
    };

    fetchData();

    const intervalId = setInterval(() => {
      fetchData();
    }, 10000);

    return () => clearInterval(intervalId);
  }, [userId]);

  const toggleSearchModal = () => {
    if (isNotificationsModalOpen) {
      setIsNotificationsModalOpen(false);
    }
    setIsSearchModalOpen(prev => !prev);
  };

  const toggleNotificationsModal = () => {
    if (isSearchModalOpen) {
      setIsSearchModalOpen(false);
    }
    setIsNotificationsModalOpen(prev => !prev);
  };

  const toggleGiftsModal = () => {
    if (isNotificationsModalOpen || isSearchModalOpen) {
      setIsNotificationsModalOpen(false);
      setIsSearchModalOpen(false);
    }
    setIsGiftsModalOpen(prev => !prev);
  }

  if (loading) {
    return <Spinner />
  }

  return (
    <>
      <TopBarContainer>
        <Title>Ciao, {username ? `${username}!` : "bentornato!"}</Title>

        <SearchContainer>
          <SearchIcon />
          <SearchBar
            readOnly
            type="text"
            placeholder="Cerca qualcosa..."
            onClick={toggleSearchModal}
            disabled={isNotificationsModalOpen}
          />
          <CtrlK>
            <Keycap>CTRL</Keycap>
            <Keycap>K</Keycap>
          </CtrlK>
        </SearchContainer>

        <IconButtonContainer hasGiftButton={role === "Owner"}>
          <IconButton
            hasNotifications={hasNotifications}
            onClick={async () => {
              await fetchNotifications();
              toggleNotificationsModal();
            }}
            disabled={isNotificationsModalOpen || isGiftsModalOpen}
          >
            <FaBell />
          </IconButton>
          <IconButton disabled={isNotificationsModalOpen || isGiftsModalOpen}>
            <FaEnvelope />
          </IconButton>
          {(role === "Owner") && (
            <IconButton
              className="gift-button"
              onClick={toggleGiftsModal}
              disabled={isNotificationsModalOpen || isGiftsModalOpen}
            >
              <FaGift />
            </IconButton>
          )}
          <IconButton
            className="search-button"
            onClick={toggleSearchModal}
            disabled={isNotificationsModalOpen || isGiftsModalOpen}
          >
            <FaSearch />
          </IconButton>
        </IconButtonContainer>

        <ProfileContainer>
          <ProfileImage
            src={profilePic || "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"}
            alt="Profile"
          />
          <ProfileDetails>
            <UserName>{username}</UserName>
            <UserRole>{role ? `${role}` : "Nessun ruolo"}</UserRole>
          </ProfileDetails>
        </ProfileContainer>
      </TopBarContainer>

      {isNotificationsModalOpen && (
        <NotificationsModal
          fetchNotifications={fetchNotifications}
          userId={userId}
          mentions={notifications.mentions}
          followers={notifications.followers}
          invites={notifications.invites}
          sistema={notifications.sistema}
          read={notifications.read}
          trashed={notifications.trashed}
          onClose={toggleNotificationsModal}
        />
      )}

      {isSearchModalOpen && (
        <SearchModal
          isOpen={isSearchModalOpen}
          onClose={toggleSearchModal}
        />
      )}

      {isGiftsModalOpen && (
        <GiftsModal
          onClose={toggleGiftsModal}
        />
      )}

    </>
  );
};

export default TopBar;