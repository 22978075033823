import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { jwtDecode } from "jwt-decode";
import SideBar from './_components/SideBar';
import { validateToken } from '../../../auth/auth';
import TopBar from './_components/TopBar';

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Spinner = styled.div`
  border: 8px solid rgba(0, 0, 0, 0.1); /* Light border */
  border-left-color: #8A3518; /* Spinner color */
  border-radius: 50%;
  width: 40px; /* Size of the spinner */
  height: 40px; /* Size of the spinner */
  animation: ${spin} 1s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center the spinner */
  z-index: 1000; /* Ensure spinner is above other components */
`;

const AdminPageContainer = styled.div`
  display: flex;
  position: relative; /* For absolute positioning of the spinner */
`;

const MainContent = styled.div`
  flex: 1; 
  display: flex;
  flex-direction: column;
`;

const AdminPage = () => {
  const [decodedToken, setDecodedToken] = useState(null);
  const [loading, setLoading] = useState(true); 
  const navigate = useNavigate();

  useEffect(() => {
    const checkToken = async () => {
      const token = localStorage.getItem('token');

      setTimeout(async () => {
        const isValid = await validateToken(token);

        if (!isValid) {
          console.error('Invalid or expired token');
          localStorage.removeItem('token');
          navigate('/');
        } else {
          try {
            const decoded = jwtDecode(token);
            setDecodedToken(decoded);
          } catch (error) {
            console.error('Failed to decode token', error);
          }
        }

        setLoading(false); 
      }, 3000); 
    };

    checkToken();
  }, [navigate]);

  if (loading) {
    return <Spinner />; 
  }

  return (
    <AdminPageContainer>
      <SideBar />
      <MainContent>
        <TopBar />
      </MainContent>
    </AdminPageContainer>
  );
};

export default AdminPage;