import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { AiOutlineWarning } from "react-icons/ai";
import { toast } from 'react-hot-toast';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.9);
  }
`;

const blurIn = keyframes`
  from {
    backdrop-filter: blur(0px);
  }
  to {
    backdrop-filter: blur(8px);
  }
`;

const blurOut = keyframes`
  from {
    backdrop-filter: blur(8px);
  }
  to {
    backdrop-filter: blur(0px);
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: ${(props) => (props.isClosing ? 'blur(0px)' : 'blur(8px)')};
  animation: ${(props) => (props.isClosing ? blurOut : blurIn)} 0.5s ease;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px 25px;
  border-radius: 12px;
  border: 5px solid #880808;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: left;
  animation: ${(props) => (props.isClosing ? fadeOut : fadeIn)} 0.5s ease;
  position: relative;
  max-width: 450px;
  width: 100%;
`;

const ModalHeader = styled.div`
  display: flex;
  align-items: flex-start; /* Aligns the icon with the text */
  font-size: 18px;
  margin-bottom: 10px;  /* Reduced margin for better alignment */
  margin-top: 0;        /* Removed top margin */
`;

const WarningIcon = styled.div`
  background-color: #fdecea;
  color: #d9534f;
  padding: 8px; /* Smaller padding for a smaller circle */
  border-radius: 50%;
  margin-right: 15px;
  font-size: 18px; /* Reduced font size for a smaller circle */
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalHeaderText = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeaderTitle = styled.h3`
  margin: 0;
  font-size: 18px;
  font-weight: bold;
`;

const HeaderDescription = styled.p`
  margin: 10px 0 0 0;
  font-size: 14px;
  font-weight: 500;
  color: #555;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
`;

const CancelButton = styled.button`
  background-color: #fff;
  color: black;
  border: 1px solid #ccc;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  letter-spacing: 0.5px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const DeleteButton = styled.button`
  background-color: #D22B2B;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  letter-spacing: 0.5px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #A52A2A;
  }
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 20px;
  color: #D22B2B;
  transition: all 0.3s ease;

  &:hover {
    color: #D22B2B;
  }

  &:focus {
    outline: none;
  }
`;

const DeleteNotificationModal = ({ notificationTitle, onConfirmDelete, onCancel }) => {
    const [isClosing, setIsClosing] = useState(false);

    const handleClose = () => {
        setIsClosing(true);
        setTimeout(onCancel, 500); 
    };

    const handleDelete = () => {
        setIsClosing(true);
        setTimeout(() => {
            onConfirmDelete();
            toast.success(`Notifica ${notificationTitle} eliminata con successo!`); 
        }, 500);
    };

    return (
        <>
            <ModalOverlay isClosing={isClosing}>
                <ModalContent isClosing={isClosing}>
                    <CloseButton onClick={handleClose}>✕</CloseButton>
                    <ModalHeader>
                        <WarningIcon>
                            <AiOutlineWarning size={20} style={{ fontWeight: 'bold' }} /> 
                        </WarningIcon>
                        <ModalHeaderText>
                            <HeaderTitle>Eliminazione Notifica</HeaderTitle>
                            <HeaderDescription>
                                Sei sicuro di voler eliminare definitivamente la notifica "<span style={{ fontWeight: 'bold' }}>{notificationTitle}</span>"? <br /> <br /> <span style={{ color: '#D22B2B', fontWeight: 'bold' }}>Attenzione: quest'azione è irreversibile</span>.
                            </HeaderDescription>
                        </ModalHeaderText>
                    </ModalHeader>
                    <ButtonContainer>
                        <CancelButton onClick={handleClose}>Annulla</CancelButton>
                        <DeleteButton onClick={handleDelete}>Elimina</DeleteButton>
                    </ButtonContainer>
                </ModalContent>
            </ModalOverlay>
        </>
    );
};

export default DeleteNotificationModal;